import React, { useState } from "react";
import ConferenceComp from "components/translationConferences/conferenceComp";
import Bussiness from "assets/images/business.png";
import customerSupport from "assets/images/customer-support.png";
import Guarantee from "assets/images/guarantee.png";
import { CountryArray } from "utils/constants";
import { useForm } from "react-hook-form";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { graphql } from "gatsby";
import Pagination from "components/TranslatorHub/pagination";
import Layout from "components/layout";
import SEO from "components/seo";
import ConferenceFilteredComp from "./conferenceFilteredComp";

export const ConfrenceQuery = graphql`
  query confrenceQuery($limit: Int!, $skip: Int!) {
    allConference(
      sort: { fields: [data___startdate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            slug
            conf_image
            country
            description
            enddate
            startdate
            title
            cover_pic
            place
          }
        }
      }
    }
  }
`;

const Confrence = (props) => {
  const conferenceData = props.data.allConference.edges;
  const [filteredData, setFilteredData] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { country, Fromdate, ToDate } = data;
    try {
      const newEndpoints = endpoints.conference.getConferenceData.replace(
        "?country={country}&conf_from={Fromdate}&conf_to={ToDate}",
        "?country=" + country + "&conf_from=" + Fromdate + "&conf_to=" + ToDate
      );
      const body = await http().get(newEndpoints);
      setFilteredData(body);
    } catch (error) {
      console.log(error);
    }
  };

  let canonical = "/translation-conference";
  if (props.pageContext.currentPage !== 1) {
    canonical = "/translation-conference/p/" + props.pageContext.currentPage;
  }
  return (
    <Layout>
      <SEO
        title="Upcoming Translation Conferences - Events for 2022 and Beyond"
        description="A comprehensive list of future translation conferences, localization conferences and various events within the languages and translation industry. You can look for translation events which take place in specific locations and dates."
        keywords="upcoming translation conferences, translation conferences 2016, translation conferences, localization conferences, language conference"
        slug={filteredData ? "/translation-conference" : canonical}
      />
      <div className="flex items-center justify-cenhter flex-col xs:font-primary">
        <div className="flex items-center justify-center w-full bg-translation-confrence bg-no-repeat bg-cover bg-center h-600">
          <div className="max-w-screen-2xl bg-black text-white pt-10 pb-4 px-6 lg:py-10 lg:px-14 bg-opacity-80 flex items-center justify-center flex-col lg:w-930 lg:h-255 mx-5 lg:mx-0">
            <h1 className="text-2xl lg:text-4xl pb-8 lg:pb-10 w-full font-normal">
              TRANSLATION CONFERENCES
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col lg:flex-row items-center">
                <div className="grid grid-cols-1 place-content-center gap-5 lg:gap-6 lg:grid-cols-3">
                  <div className="flex md:inline-flex flex-col">
                    <label
                      htmlFor="country"
                      className="pl-2 pb-2 font-semibold"
                    >
                      Location
                    </label>
                    <select
                      {...register("country")}
                      id="countries"
                      name="country"
                      className="text-inputGray py-2 px-2 bg-white rounded-sm h-10 border border-inputBorder"
                    >
                      <option disabled selected>
                        Location
                      </option>
                      {CountryArray?.map(({ name }, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex md:inline-flex flex-col">
                    <label htmlFor="from" className="pl-2 pb-2 font-semibold">
                      From
                    </label>
                    <input
                      type="date"
                      name="Fromdate"
                      className="text-inputGray py-3 px-2 bg-white rounded-sm h-10 border border-inputBorder"
                      placeholder="From"
                      {...register("Fromdate")}
                    />
                  </div>

                  <div className="flex md:inline-flex flex-col">
                    <label htmlFor="to" className="pl-2 pb-2 font-semibold">
                      To
                    </label>
                    <input
                      type="date"
                      name="ToDate"
                      className="text-inputGray py-3 px-2 bg-white rounded-sm h-10 border border-inputBorder"
                      placeholder="To"
                      {...register("ToDate")}
                    />
                  </div>
                </div>
                <div className="lg:ml-10 lg:self-end my-5 w-full lg:w-20 lg:my-0">
                  <button
                    type="submit"
                    className="inline text-bottom bg-lightBlue px-4 py-2 w-full lg:w-20"
                  >
                    OK
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className=" bg-opacity-80 bg-black"></div>
        </div>
        <div className="flex items-center justify-center bg-darkestgray w-full">
          <div className="flex justify-evenly max-w-screen-2xl w-full xl:w-8/12 flex-col md:flex-row">
            <div className="md:w-7/12 w-full px-6 sm:px-0">
              <h2 className="text-lightBlue text-xl lg:text-2xl pt-14 pb-8">
                LANGUAGE & TRANSLATION CONFERENCES
              </h2>
              {filteredData ? (
                <ConferenceFilteredComp filteredData={filteredData} />
              ) : (
                <ConferenceComp conferenceData={conferenceData} />
              )}
              {!filteredData ? (
                <Pagination
                  totalPages={props.pageContext.totalPages}
                  currentPage={props.pageContext.currentPage}
                  baseUrl="/translation-conference"
                />
              ) : null}
            </div>
            {/* why choose us */}
            <div className="md:w-1/4 w-full mt-11 sm:border-l sm:border-white sm:pl-6 h-2/3">
              <p className="text-lightBlue text-3xl  pb-8 xs:font-primary text-center sm:text-left">
                Why choose us
              </p>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src={customerSupport}
                  alt="customer support"
                  className="m-auto inline-block w-[92px] h-[88px]"
                />
                <p className="text-lg xs:font-primary text-white mt-4">
                  24/7 Human Support
                </p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src={Guarantee}
                  alt="guarantee"
                  className="m-auto inline-block w-[71px] h-[91px]"
                />
                <p className="text-lg xs:font-primary text-white mt-4">
                  1 Year Guarantee
                </p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src={Bussiness}
                  alt="business"
                  className="m-auto inline-block w-[83px] h-[77px]"
                />
                <p className="text-lg xs:font-primary text-white mt-4">
                  95,000 Business Customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Confrence;