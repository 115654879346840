import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";

function ConferenceComp({ conferenceData }) {
  return (
    <>
      {conferenceData ? (
        <>
          {conferenceData.map(({ node }, i) => {
            const { data } = node;
            return (
              <div className="border-t border-white  text-left" key={i}>
                <p className="xs:font-primary w-auto pt-6 pb-3 text-white">
                  <div className="flex justify-left font-light italic">
                    <p className="block text-sm">{data.place}, &nbsp;</p>
                    <p className="block text-sm">{data.country}, &nbsp;</p>
                    <p>
                      {moment(data.startdate).format("DD/MM/YYYY")}, &nbsp;{" "}
                    </p>
                    <p>{moment(data.enddate).format("DD/MM/YYYY")}</p>
                  </div>
                  <p className="font-light text-lg">{data.title}</p>
                </p>
                <Link
                  className="text-lightBlue text-sm cursor-pointer"
                  to={data.slug}
                >
                  Read more
                </Link>
                <span
                  style={{
                    display: "block",
                    paddingTop: "8px",
                    paddingBottom: "16px",
                  }}
                >
                  <i to="/">
                    <FontAwesomeIcon
                      icon={faShareAlt}
                      className="text-lightBlue text-3xl"
                    />
                  </i>
                  <span className="ml-4 bg-white inline-block my-auto py-1 px-2 relative ">
                    <a
                      href="https://www.facebook.com/Tomedes.translation/"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="text-facbook  text-3xl rounded-full mr-2"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/tomedes.com/"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="text-linkdein text-3xl rounded-full mr-2"
                      />
                    </a>
                    <a href="https://twitter.com/Tomedes" target="_blank">
                      {/* <FontAwesomeIcon
                        icon={faTwitterSquare}
                        className="text-twitter text-3xl rounded-full"
                      /> */}
                      <svg
                        // className="social_media_icon_hover"
                        fill=""
                        width="40"
                        zoomAndPan="magnify"
                        viewBox="0 0 375 374.999991"
                        height="40"
                      >
                        <defs>
                          &lt; d="M 8.917969 7.773438 L 367.417969 7.773438 L
                          367.417969 366.273438 L 8.917969 366.273438 Z M
                          8.917969 7.773438 " /&gt;{" "}
                        </defs>

                        <g transform="translate(90,80) ">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 44 44"
                            version="1.1"
                            height="full"
                            width="full"
                          >
                            <path
                              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                              fill="#ffffff"
                            ></path>
                          </svg>
                        </g>
                      </svg>
                    </a>
                    <FontAwesomeIcon
                      icon={faCaretLeft}
                      className="text-white absolute -left-2.5 text-3xl"
                    />
                  </span>
                </span>
              </div>
            );
          })}
          {/* <div className="border-t border-white text-left last:visible invisible last:py-6 last:mb-14"> */}
          <div className="border-t border-white text-left py-6 mb-14">
            <Link to={`/translation-conference/add`}>
              <button className="bg-lightBlue px-3 py-3 font-semibold text-xs text-white items-center">
                ADD A CONFERENCE
              </button>
            </Link>
          </div>
        </>
      ) : (
        <h3 className="text-red font-bold">THERE IS NO CONFERENCE</h3>
      )}
    </>
  );
}
export default ConferenceComp;
